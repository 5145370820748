
import React, { useState, useEffect, Fragment, Component } from 'react'

// import ListenerManager from './common/ListenerManager'

import {connect} from "@voxeet/react-redux-5.1.1";

import axios from 'axios';

import ConnectionManager from '../common/ConnectionManager'

import { withRouter } from "react-router-dom";

import MainViewContainer,{PAGES} from './MainViewContainer'

const mapStateToProps = (state) => ({
      conferenceStore: state.voxeet.conference,
      errorStore: state.voxeet.error,
      participantsStore: state.voxeet.participants,
})

class SettingView extends Component{
  constructor(props){
    super(props);
    this.state = {

    }
    // this.createDummyData()
  }

  buttonData={
    PROFILE:{
      text:'Profile',
      onClick:()=>{
        // ConnectionManager.instance.logout()
        // let res = ConnectionManager.instance.getIsLoginFromSession()
        // console.log(res)
        // console.log(this,this.props,this.props.history)
        // if(this.props.history){
        //   let path = `/`;
        //   this.props.history.replace(path);
        // }

        // ConnectionManager.instance.api_refreshToken();


      }
    },
    LOGGOUT:{
      text:'Logout',
      onClick:()=>{
        ConnectionManager.instance.api_logout()
        ConnectionManager.instance.logout()
        // let res = ConnectionManager.instance.getIsLoginFromSession()
        // console.log(res)
        // console.log(this,this.props,this.props.history)
        if(this.props.history){
          let path = `/`;
          this.props.history.replace(path);
        }

      }
    }
  }

  componentDidMount(){
    ConnectionManager.instance.api_getUserInfo({

    })
  }
  componentDidUpdate(){

  }




  renderSettingButton=({text,onClick})=>{
    return (
      <div style={Style.settingBtn} onClick={()=>onClick()}>
      <label style={Style.settingBtnLabel}>
      { text }
      </label>
      </div>
    )
  }

  renderSetting=()=>{
    let userInfo = ConnectionManager.instance.getUserInfoAlsoSavedUserInfo()
    let userName = '';
    if(userInfo){
      userName = userInfo.name
    }
    return(
      <div style={Style.settingContainer}>
        {
          <div style={Style.nameRowDiv}>
            <label style={Style.nameRowLabel}>
            { userName }
            </label>
          </div>
        }
        {
          this.renderSettingButton(this.buttonData.PROFILE)
        }
        {
          this.renderSettingButton(this.buttonData.LOGGOUT)
        }
      </div>
    )
  }



  render (){return (
    <MainViewContainer
    page_id={PAGES.PAGE_SETTING}
    >
    {
      this.renderSetting()
    }
    </MainViewContainer>
  )}



}

const Style={

  settingContainer:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-start',
    alignItems:'center',
    // paddingTop:30,
    // border:'2px solid #0f0',
  },
  settingBtn:{
    marginTop:20,
    backgroundColor:'#dddddd',
    minWidth:240,
    height:40,
    display:'flex',
    // flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    // marginRight:40,
    borderRadius:6,
    padding:0,
  },
  settingBtnLabel:{

  },
  nameRowDiv:{
    height:40,
    paddingRight:20,
    // border:'2px solid #0f0',
    width:'100%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-end',
    alignItems:'center',
  },
  nameRowLabel:{
    fontSize:16,
    fontWeight:'bold',
    margin:0,
  }
}

export default (withRouter(SettingView))
// export default  connect(mapStateToProps) (LoginView)
