export const BROADCAST_KICK = "Kick_Event";
export const BROADCAST_KICK_ADMIN_HANG_UP = "Kick_Admin_Hang_up";
export const CHAT_MESSAGE = "Chat_Message";
export const RECORDING_STATE = "Recording_State";

const broadcastType = [
  BROADCAST_KICK,
  BROADCAST_KICK_ADMIN_HANG_UP,
  CHAT_MESSAGE,
  RECORDING_STATE
];

export default broadcastType;
