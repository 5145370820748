import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "@voxeet/react-redux-5.1.1";
import attachIcon from '../../../static/images/icons/icon-attach.svg';
import sendIcon from '../../../static/images/icons/icon-tag.svg';
import { strings } from "../../languages/localizedStrings";
import ListenerManager from '../../../../../../common/ListenerManager'

class AttendeesChatInputContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ""
    };
    this.onContentChange = this.onContentChange.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    const { sendMessage } = this.props;
    if (event.keyCode === 13) {
      sendMessage(this.state.content);
      this.setState({ content: "" });
      event.preventDefault();
    }
  }

  onContentChange(e) {
    let content = e.target.value;
    this.setState({ content: content });
  }

  handleClickFilePresentation=()=> {
    document.getElementById("filePresentationUpload").click();
  }
  handleChange=(e)=>{
    // this.props.convertFilePresentation(e.target.files[0]);
    // this.setState({ opened: !this.state.opened });
    const { currentUser,userPlaceholder} = this.props;
    console.log('handleChange:',e.target.files[0],currentUser)
    let payload = {
      content: '',
      time: Date.now(),
      type: "file",
      name: currentUser.name,
      avatarUrl: currentUser.avatarUrl || userPlaceholder,
      ownerId: currentUser.participant_id
    };

    let data = {
        payload:payload,
        file:e.target.files[0]
    }
    ListenerManager.emitEvent('sendFile',data)
  }

  render() {
    const { sendMessage } = this.props;
    const { content } = this.state;
    return (
      <div className="container-input-chat">
        <div className="container-attach">
          <a
            onClick={e => {
              // this.setState({ content: "" });
              // sendMessage(this.state.content);
              // e.preventDefault();
              this.handleClickFilePresentation()
            }}
          >
            <img src={attachIcon} />
            {/* {strings.sendMessage} */}
          </a>
          <input
            ref={(e)=>this.currentInput = e}
            type="file"
            id="filePresentationUpload"
            // accept="application/pdf"
            onChange={this.handleChange}
            style={{ display: "none" }}
            onClick={(event)=> {
               event.target.value = null
            }}
          />
        </div>
        <div className="container-input">
          <input
            placeholder={strings.placeholderChat}
            onChange={this.onContentChange}
            value={content}
            id="contentInputChat"
            className="input-message"
          ></input>
        </div>
        <div className="container-send">
          <a
            onClick={e => {
              this.setState({ content: "" });
              sendMessage(this.state.content);
              e.preventDefault();
            }}
          >
            <img src={sendIcon} />
            {/* {strings.sendMessage} */}
          </a>
        </div>
      </div>
    );
  }
}

AttendeesChatInputContainer.propTypes = {
  sendMessage: PropTypes.func.isRequired
};

export default AttendeesChatInputContainer;
