import axios from 'axios';
import webSocket from 'socket.io-client'
import ListenerManager from './ListenerManager'
const _ENDPOINT = "https://dolby-webrct.epic-comm.net:65003";

export default class ConnectionManager{
  static get ENDPOINT (){
    return _ENDPOINT
  }

  static _instance = null
  static getInstance(){
    if(ConnectionManager._instance == null){
      ConnectionManager._instance = new ConnectionManager();
    }
    return this._instance;
  }
  static get instance (){
    if(ConnectionManager._instance == null){
      ConnectionManager._instance = new ConnectionManager();
    }
    return this._instance;
  }
  // variables
  currentLoginToken = "";
  currentUserInfo = null;
  currentOauthObj = null;
//--------------- normal function
  logout (){
    this.currentLoginToken = "";
    this.currentUserInfo = null;
    this.currentOauthObj = null;

    sessionStorage.setItem('access_token','')
    sessionStorage.setItem('user_info','')
    sessionStorage.setItem('oauth_obj','')

  }
  setLoginedData(token,userInfo){
    this.currentLoginToken = token
    this.currentUserInfo = userInfo

    sessionStorage.setItem('access_token',token)
    sessionStorage.setItem('user_info',JSON.stringify(userInfo))
  }
  setOauthTokenObj(oauthTokenObj){
    this.currentOauthObj = oauthTokenObj
    sessionStorage.setItem('oauth_obj',JSON.stringify(oauthTokenObj))
  }
  // if user refresh the page, data in this class will gone, need to load saved token and user_info
  loadSessionDataToThis(){
    if(this.currentLoginToken == null
      || this.currentLoginToken== ''){
      let savedToken = sessionStorage.getItem('access_token')
      let savedUserInfo = sessionStorage.getItem('user_info')
      let savedOauthObj = sessionStorage.getItem('oauth_obj')

      this.currentLoginToken = savedToken;
      if(savedUserInfo){
        this.currentUserInfo = JSON.parse( savedUserInfo);
      }
      if(savedOauthObj){
        this.currentOauthObj = JSON.parse( savedOauthObj);
      }

    }
  }


  getIsLoginFromSession(){
    let savedToken = sessionStorage.getItem('access_token')
    // let savedUserInfo = sessionStorage.getItem('user_info')
    if(savedToken){
      return true
    }else {
      return false
    }
  }
  getSavedSessionToken(){
    return sessionStorage.getItem('access_token')
  }

  getUserInfoAlsoSavedUserInfo(){
    if(this.currentUserInfo){

    }else {
      let savedUserInfo =  sessionStorage.getItem('user_info');
      console.log('savedUserInfo',savedUserInfo)
      if(savedUserInfo){
        this.currentUserInfo = JSON.parse(savedUserInfo)
      }
    }
    console.log('getUserInfoAlsoSavedUserInfo',this.currentUserInfo)
    return this.currentUserInfo
  }

  getOauthObjAlsoSavedOauthObj(){
    if(this.currentOauthObj){

    }else {
      let savedOauthObj =  sessionStorage.getItem('oauth_obj');
      console.log('savedOauthObj',savedOauthObj)
      if(savedOauthObj){
        this.currentOauthObj = JSON.parse(savedOauthObj)
      }
    }
    console.log('getOauthObjAlsoSavedOauthObj',this.currentOauthObj)
    return this.currentOauthObj
  }

//--------------- api
createReturnObj=()=>{
  return{
    isSuccess:false,
    data:null,
    err_msg:'',
  }
}
  test_post_Api(){
    console.log('123')
    const formData = new FormData();
    formData.append('test','1233333')
    axios.post(_ENDPOINT + '/postTest', formData, {
        onUploadProgress: (ProgressEvent) => {
            let progress = Math.round(
            ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            // setProgess(progress);
            console.log(progress)
        }
    }).then(res => {
        console.log('testApi res:',res);

    }).catch(err => console.log(err))
  }
  test_get_Api(){
    console.log('123')
    const formData = new FormData();
    formData.append('param1','1')
    formData.append('param2','2')

    let queryString = new URLSearchParams(formData).toString()
    console.log(queryString)
    axios.get(_ENDPOINT + '/getTest?'+queryString).
    then(res => {
        console.log('testApi res:',res);

    }).catch(err => console.log(err))
  }

  api_login = async (_id,_pw,callback)=>{
    console.log('api_login:')
    let returnObj = this.createReturnObj()

    let headers = {
      'Content-Type':'application/x-www-form-urlencoded',
      // 'Content-Type':'multipart/form-data',
      'login': _id,
      'password': _pw,
      'app_id':'dolby-webrct',
      'app_secret':'dolby_secret',
    }

    let formData = new FormData();
    formData.append('login', _id )
    formData.append('password', _pw)
    formData.append('app_id','dolby-webrct')
    formData.append('app_secret','dolby_secret')

    let postRes = await axios.post(_ENDPOINT + '/login', formData, {
        // headers:headers,
        onUploadProgress: (ProgressEvent) => {
            let progress = Math.round(
            ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            // setProgess(progress);
            console.log(progress)
        }
    })
    // let postRes = await axios.post(_ENDPOINT + '/login', {}, headers)
    .then(res => {
        console.log(res);
        // getFile({ name: res.data.name,
        //          path: ENDPOINT + res.data.path
        //        })
        if(res.data && res.data.status == "Y"){
          returnObj.isSuccess = true
        }else {

        }
        returnObj.data =  res.data

        return returnObj
    }).catch(err =>{
      console.log(err)
      returnObj.err_msg = err
      return returnObj
    })
    console.log('postRes:',postRes);
    return postRes;

  }

  api_getUserInfo = async (callback)=>{
    console.log('api_getUserInfo:')
    let returnObj = this.createReturnObj()

    this.loadSessionDataToThis()

    let headers ={
      authorization: this.currentLoginToken,
    }

    let formData = new FormData();
    // formData.append('token',this.currentLoginToken)


    let queryString = new URLSearchParams(formData).toString()
    console.log(queryString)
    let getRes =await axios.get(_ENDPOINT + '/getUserInfo?'+queryString,{headers:headers}).
    then(res => {
        console.log('api_getUserInfo res:',res);

        returnObj.isSuccess = true;
        returnObj.data = res.data;

    }).catch(err => console.log(err))

    console.log('getRes:',getRes);
    return getRes;

  }


  api_getRoomList = async (_offset,callback)=>{
    console.log('api_getRoomList:')
    let returnObj = this.createReturnObj()

    this.loadSessionDataToThis()

    let headers ={
      authorization: this.currentLoginToken,
    }

    let formData = new FormData();
    formData.append('offset',_offset)


    let queryString = new URLSearchParams(formData).toString()
    console.log(queryString)
    let getRes =await axios.get(_ENDPOINT + '/getRoomList?'+queryString,{headers:headers}).
    then(res => {
        console.log('api_getRoomList res:',res);

        returnObj.isSuccess = true;
        returnObj.data = res.data;

    }).catch(err => console.log(err))

    if(callback){
      callback(returnObj)
    }
    console.log('returnObj :',returnObj);
    return returnObj;

  }

  api_enterRoom = async (_room_id,callback)=>{
    console.log('api_enterRoom:')
    let returnObj = this.createReturnObj()

    this.loadSessionDataToThis()

    let headers ={
      authorization: this.currentLoginToken,
    }

    let formData = new FormData();
    formData.append('room_id',_room_id)

    let postRes =await axios.post(_ENDPOINT + '/enterRoom', formData, {
        headers:headers,
    }).
    then(res => {
        console.log('api_enterRoom res:',res);

        returnObj.isSuccess = true;
        returnObj.data = res.data;

    }).catch(err => console.log(err))

    if(callback){
      callback(returnObj)
    }
    console.log('returnObj :',returnObj);
    return returnObj;

  }

  api_leaveRoom = async(callback)=>{
    console.log('api_leaveRoom:')
    let returnObj = this.createReturnObj()

    this.loadSessionDataToThis()

    let headers ={
      authorization: this.currentLoginToken,
    }

    let formData = new FormData();
    // formData.append('room_id',_room_id)

    let postRes =await axios.post(_ENDPOINT + '/leaveRoom', formData, {
        headers:headers,
    }).
    then(res => {
        console.log('api_leaveRoom res:',res);

        returnObj.isSuccess = true;
        returnObj.data = res.data;

    }).catch(err => console.log(err))

    if(callback){
      callback(returnObj)
    }
    console.log('returnObj :',returnObj);
    return returnObj;
  }


  api_refreshToken = async(callback)=>{
    console.log('api_refreshToken:')
    let returnObj = this.createReturnObj()

    this.loadSessionDataToThis()

    let headers ={
      authorization: this.currentLoginToken,
    }

    let formData = new FormData();
    // formData.append('',)


    let queryString = new URLSearchParams(formData).toString()
    console.log(queryString)
    let getRes =await axios.get(_ENDPOINT + '/refreshToken?'+queryString,{headers:headers}).
    then(res => {
        console.log('api_refreshToken res:',res);

        returnObj.isSuccess = true;
        returnObj.data = res.data;

    }).catch(err => console.log(err))

    if(callback){
      callback(returnObj)
    }
    console.log('returnObj :',returnObj);
    return returnObj;
  }


  api_logout = async(callback)=>{
    console.log('api_logout:')
    let returnObj = this.createReturnObj()

    this.loadSessionDataToThis()

    let headers ={
      authorization: this.currentLoginToken,
    }

    let formData = new FormData();
    // formData.append('',)


    let queryString = new URLSearchParams(formData).toString()
    console.log(queryString)

    let postRes =await axios.post(_ENDPOINT + '/logout', formData, {
        headers:headers,
    }).
    then(res => {
        console.log('api_logout res:',res);
        returnObj.isSuccess = true;
        returnObj.data = res.data;

    }).catch(err => console.log(err))

    if(callback){
      callback(returnObj)
    }
    console.log('returnObj :',returnObj);
    return returnObj;
  }




  api_uploadFile(data,room_alias){
    console.log('api_uploadFile:',data)
    console.log('sendFileFromListener:',data)
    console.log(data.payload.ownerId)
    // console.log('currentLoginToken',this.currentLoginToken)

    // let tempToken = this.getSavedSessionToken()
    // console.log('tempToken',tempToken)

    this.loadSessionDataToThis()

    let messagePayload = data.payload
    let fileData = data.file
    console.log('currentLoginToken:',this.currentLoginToken)
    let headers={
      'Content-Type':'application/x-www-form-urlencoded',
      'authorization': this.currentLoginToken,

    }
    const formData = new FormData();
    formData.append('file',fileData)
    // formData.append('payload',JSON.stringify(messagePayload))
    // formData.append('token',this.currentLoginToken);
    formData.append('owner_id',data.payload.ownerId);
    formData.append('room_alias',room_alias);

    console.log('currentLoginToken',this.currentLoginToken)

    axios.post(_ENDPOINT + '/upload', formData, {
        headers:headers,
        onUploadProgress: (ProgressEvent) => {
            let progress = Math.round(
            ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            // setProgess(progress);
            console.log(progress)
        }
    }).then(res => {
        console.log(res);
        // getFile({ name: res.data.name,
        //          path: ENDPOINT + res.data.path
        //        })
    }).catch(err => console.log(err))
  }

  //------------------ socket
  currentSocket = null

  connectWebSocket(){
    if(this.currentSocket == null){
      // let new_ws = webSocket(ENDPOINT) // http
      let extra_query = ''
      let saveToken = this.getSavedSessionToken() ;
      if(saveToken){
        // extra_query = '/?token=' +saveToken
      }
      let new_ws = webSocket(_ENDPOINT+extra_query,{ // https
        secure: true,
        // rejectUnauthorized:false
        // transports:[
        //   // 'polling'
        // ],
        origins: '*:*',
        transportOptions: {
          polling: {
            extraHeaders: {
              // 'authorization': 'Bearer xxxxx',
              // 'authorization': 'xxxxx',
              'authorization':saveToken,
            },
          },
        },
      })
      this.currentSocket =  new_ws
    }
  }
  closeWebSocket(){
    console.log('closeWebSocket!!')
    if(this.currentSocket !=null){
      // this.currentSocket.removeAllListeners([
      //   'FromAPI',
      //   'getMessage',
      //   'getMessageAll',
      //   'getMessageLess',
      //   'addRoom',
      //   'leaveRoom',
      //   'disConnection',
      // ])
      this.currentSocket.removeListener('getMessageAll')

      this.currentSocket.close()

      this.currentSocket = null
    }
  }

  setUpListener(){

  }


  initWebSocketListener({
    callback_FromAPI,
    callback_getMessage,
    callback_getMessageAll,
    callback_getMessageLess,
    callback_disConnection,
  }){
    console.log('initWebSocketListener - start')
    if(this.currentSocket){
      let ws = this.currentSocket;
      ws.on("FromAPI", data => {
       // this.setState({
       //   response:data
       // })
       if(callback_FromAPI){
         callback_FromAPI(data)
       }
     });
       //對 getMessage 設定監聽，如果 server 有透過 getMessage 傳送訊息，將會在此被捕捉
       ws.on('getMessage', message => {
           console.log(message)
       })
       ws.on('getMessageAll', message => {
           console.log('getMessageAll:',message)
           // let dataParsed = JSON.parse(message)
          // this.props.dispatch(ChatActions.addMessage(dataParsed));
          // ListenerManager.emitEvent('getMessageAll',dataParsed)
          if(callback_getMessageAll){
            // callback_getMessageAll(dataParsed)
            callback_getMessageAll(message)
          }
       })
       ws.on('getMessageLess', message => {
           console.log('getMessageLess:',message)
           // dispatch(this._newBadgeMessage());
           // let dataParsed = JSON.parse(message)
          // this.props.dispatch(ChatActions.addMessage(dataParsed));
          // ListenerManager.emitEvent('getMessageLess',dataParsed)
          if(callback_getMessageLess){
            // callback_getMessageLess(dataParsed)
            callback_getMessageLess(message)
          }
       })
       ws.on('addRoom', message => {
           console.log(message)
       })
       ws.on('leaveRoom', message => {
           console.log(message)
       })
       // Server 通知完後再傳送 disConnection 通知關閉連線
       ws.on('disConnection', () => {
           ws.close()
           this.currentSocket = null
           if(callback_disConnection){
             callback_disConnection()
           }
       })
       console.log('initWebSocketListener end!')
    }
  }
  socket_sendMessage(eventName,data,alias){
    if(this.currentSocket){
      console.log('socket_sendMessage:',eventName)
      let content = '';
      let  defaultPayload = {
        content: 'default text',
        time: Date.now(),
        type: "text",
        name: '',
        avatarUrl: '',
        ownerId: '',
        room_alias:alias,
      };
      console.log(data)
      if(data){
        data.data.room_alias = alias
        content = JSON.stringify(data.data);
      }else {
        content = JSON.stringify(defaultPayload);
      }
      this.currentSocket.emit(eventName, content)
    }
  }

  socket_enterRoom(roomData){
    if(this.currentSocket){
      this.currentSocket.emit('enterRoom', JSON.stringify(roomData))
    }
  }

}
