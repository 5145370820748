
import React, { useState, useEffect, Fragment, Component } from 'react'

// import ListenerManager from './common/ListenerManager'

import {connect} from "@voxeet/react-redux-5.1.1";

import axios from 'axios';

import ConnectionManager from '../common/ConnectionManager'

import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
      conferenceStore: state.voxeet.conference,
      errorStore: state.voxeet.error,
      participantsStore: state.voxeet.participants,
})

export const PAGES = {
  PAGE_ROOMLIST:'PAGE_ROOMLIST',
  PAGE_SETTING:'PAGE_SETTING',
}
export const PAGE_ROOMLIST = 'PAGE_ROOMLIST'
export const PAGE_SETTING = 'PAGE_SETTING'
class MainViewContainer extends Component{
  constructor(props){
    super(props);
    this.state = {
      roomListData:[],
      currentRoomListIndex:0,
    }
    // this.createDummyData()
  }

  pageData={
    PAGE_ROOMLIST:{
      id:PAGE_ROOMLIST,
      title:'Rooms',
      // pageName:'Rooms',
      btnImg_on:require('../img/stream_on.png'),
      btnImg_off:require('../img/stream_off.png'),
      onClick:()=>{
        let path = `/RoomListView`;
        this.props.history.push(path);
      }
    },
    PAGE_SETTING:{
      id:PAGE_SETTING,
      title:'Setting',
      // pageName:'Setting',
      btnImg_on:require('../img/setting_on.png'),
      btnImg_off:require('../img/setting_off.png'),
      onClick:()=>{
        let path = `/SettingView`;
        this.props.history.push(path);
      }
    }
  }


  componentDidMount(){

  }
  componentDidUpdate(){

  }

  onClickRoomEnter=(data)=>{
    console.log('onClickRoomEnter:',data);
    let path = `/ComferenceView`;
    this.props.history.push(path);
  }

  renderBottomBTN=({id,btnImg_on,btnImg_off,currentValue,onClick})=>{
    return(
      <div  style={Style.bottomBtn} onClick={()=>{
        if(onClick){onClick()}
      }}>
        <img  style={Style.bottomBtnImg} src={id == currentValue?btnImg_on:btnImg_off}/>
      </div>
    )
  }




  render (){
    console.log(this, this.props)
    let {
      currentPage,
      page_id,
    } = this.props
    return (
    <div style={Style.basicContainer}>


    <div style={Style.topContainer}>
      <label style={Style.titleLabel}>
      {
        this.pageData[page_id].title
      }
      </label>
    </div>



      <div style={Style.listViewContainer}>
        {
          this.props.children
        }
      </div>


      <div style={Style.bottomContainer}>

        {
          this.renderBottomBTN({
            id:PAGE_ROOMLIST,
            currentValue:page_id,
            btnImg_on:require('../img/stream_on.png'),
            btnImg_off:require('../img/stream_off.png'),
            onClick:()=>{
              let path = `/RoomListView`;
              this.props.history.push(path);
            }
          })
        }
        <div  style={{width:60}}/>
        {
          this.renderBottomBTN({
            id:PAGE_SETTING,
            currentValue:page_id,
            btnImg_on:require('../img/setting_on.png'),
            btnImg_off:require('../img/setting_off.png'),
            onClick:()=>{
              let path = `/SettingView`;
              this.props.history.push(path);
            }
          })
        }

      </div>
    </div>
  )}



}

const Style={
  basicContainer:{
    backgroundColor:'#000000',
    // backgroundColor:'#dddddd',
    // display:'flex',
    flexDirection:'column',
    justifyContent:'flex-end',
    display:'flex',
    height:'100vh',
    // height:'100%',
  },
  listViewContainer:{
    backgroundColor:'#ffffff',
    display:'flex',
    flex:1,
    // height:'80vh',
    // height:'100%' ,
    overflow: 'auto',
    // flexDirection:'column',
    // justifyContent:'flex-end',
  },
  topContainer:{
    flex:0,
    backgroundColor:'#dddddd',
    height:60,
    minHeight:60,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
  },
  bottomContainer:{
    flex:0,
    backgroundColor:'#dddddd',
    height:100,
    minHeight:100,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
  },
  titleLabel:{
    fontSize:24,
  },
  bottomBtn:{
    width:60,
    height:60,
    // border: '2px solid #0f0',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
  },
  bottomBtnImg:{
    maxWidth:40,
    maxHeight:40,
  },
  roomViewContainer:{
    flex:1,
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-end',
    // borderBottom: '2px solid #0f0',
    overflow:'auto',
  },
  roomScrollNav:{
    // backgroundColor:'#ff0000',
    // maxHeight:'400px',
    display:'flex',
    flex:1,
    borderBottom: '1px solid #dddddd',
    overflow:'auto',
  },
  roomBottomContainer:{
    minHeight:60 ,
    // border:'1px solid #000000',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
  },
  roomPageIndexLabel:{
    fontSize:16,
    fontWeight:'bold',
  },
  roomScrollList:{

    // columns: 2,
    height:'auto',
    flex:1,
    margin:0,
    padding:0,
    // textIndent:'10px',
    // backgroundColor:'#0000ff',
  },
  roomContainer:{
    height:100,
    width:'100%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    // paddingLeft:40,
    // paddingRight:40,
    // borderBottomWidth:2,
    // borderColor:'#dddddd',
    'borderBottom': '1px solid #dddddd',
  },
  roomNameContainer:{
    height:60,
    marginLeft:20,
    marginRight:20,
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    // border: '1px solid #000000',
    width:'100%',
  },
  roomNameLabel:{
    fontSize:18,
  },
  roomImg:{
    marginLeft:40,
    maxWidth:60,
    maxHeight:60,
    selectDisable:'user-select:none',
  },
  roomEnterBtn:{
    backgroundColor:'#dddddd',
    minWidth:100,
    height:40,
    display:'flex',
    // flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    // marginRight:40,
    borderRadius:6,
    padding:0,
  },
  roomEnterLabel:{
    fontSize:18,
    fontWeight:'bold',
    margin:0,
  },

  settingContainer:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-start',
    alignItems:'center',
  },
  settingBtn:{
    backgroundColor:'#dddddd',
    minWidth:100,
    height:40,
    display:'flex',
    // flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    // marginRight:40,
    borderRadius:6,
    padding:0,
  },
  settingBtnLabel:{

  },

}

export default (withRouter(MainViewContainer))
// export default  connect(mapStateToProps) (LoginView)
