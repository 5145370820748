
import React, { useState, useEffect, Fragment, Component } from 'react'

// import ListenerManager from './common/ListenerManager'

import {connect} from "@voxeet/react-redux-5.1.1";

import axios from 'axios';

import ConnectionManager from '../common/ConnectionManager'

import { withRouter } from "react-router-dom";

import MainViewContainer,{PAGES} from './MainViewContainer'

const mapStateToProps = (state) => ({
      conferenceStore: state.voxeet.conference,
      errorStore: state.voxeet.error,
      participantsStore: state.voxeet.participants,
})

class RoomListView extends Component{
  constructor(props){
    super(props);
    this.state = {
      roomListData:[],
      currentRoomListIndex:0,
    }
    // this.createDummyData()
  }


  componentDidMount(){
    // setTimeout(()=>{
    //   this.createDummyData()
    // },1000)
    // console.log('componentDidMount:',this.props)
    this.refreshData(0)
  }
  componentDidUpdate(){

  }

  onPageChange =(value)=>{
    if(value > 0){
      let maxIndex = Math.ceil(this.state.roomsCount/10) -1
      if(this.state.currentRoomListIndex <maxIndex){
        this.setState({
          currentRoomListIndex:this.state.currentRoomListIndex+1,
          roomListData:[]
        },()=>{
          this.refreshData()
        })
      }else {
        this.setState({
          currentRoomListIndex:maxIndex,
          roomListData:[]
        },()=>{
          this.refreshData()
        })
      }
    }else {
      if(this.state.currentRoomListIndex >0){
        this.setState({
          currentRoomListIndex:this.state.currentRoomListIndex-1,
          roomListData:[]
        },()=>{
        this.refreshData()
        })
      }else {
        // this.refreshData(0)
        this.setState({
          currentRoomListIndex:0,
          roomListData:[]
        },()=>{
        this.refreshData()
        })
      }
    }
  }
  refreshData = ()=>{

    let _offset =  this.state.currentRoomListIndex * 10
    ConnectionManager.instance.api_getRoomList(_offset,(obj)=>{
      if(obj.isSuccess == true){
        this.setState({
          roomListData:obj.data.rooms,
          roomsCount :obj.data.all_count,
        })
      }
    })
  }
  createDummyData=()=>{
    let dataList=[];
    for (var i = 0; i < 20; i++) {
      let tempData = {
        room_id:i+1,
        room_name:'Room '+(i+1),
        // is_public:i%2 == 1? 1:0,
      }
      dataList.push(tempData)
    }
    this.setState({roomListData:dataList})
  }


  onClickRoomEnter=(data)=>{
    console.log('onClickRoomEnter:',data);

    ConnectionManager.instance.api_enterRoom(data.id,(obj)=>{
      console.log('api_enterRoom return:',obj)
      if(obj.isSuccess == true){
        if(obj.data.refreshTokenObj){
          ConnectionManager.instance.setOauthTokenObj(obj.data.refreshTokenObj)

        }else {

        }
        let path = `/ComferenceView`;
        this.props.history.push(path,{roomData:data});
      }else {

      }
    })
  }



  renderRoom=({key,data})=>{
    // console.log(key,':',data)
    return(
      <div  style={Style.roomContainer} key={key} onClick={()=>{
        // this.onClickRoomEnter(data)
      }}>
        <img style={Style.roomImg} src={require('../img/room_img.png')}/>
        <div style={Style.roomNameContainer}>
          <label style={Style.roomNameLabel}>
          {data.name}
          </label>
        </div>

        <div style={Style.roomEnterBtn} onClick={()=>{
          console.log('!!!!')
          this.onClickRoomEnter(data)
        }}>
          <label style={Style.roomEnterLabel}>
          { 'Enter' }
          </label>
        </div>
        <div style={{width:40}}/>
      </div>
    )
  }

  renderRoomList=()=>{
    return(
      <div style={Style.roomViewContainer}>
      <div style={Style.roomScrollNav}>
        {
          <ul style={Style.roomScrollList}>
            {
              this.state.roomListData.map((data,index)=>{
                return(
                  this.renderRoom({
                    key:index,
                    data:data,
                  })
              )})
            }
          </ul>
        }

      </div>
      {
        <div style={Style.roomBottomContainer}>

        { this.renderPageButton({
          img:require('../img/arrow_L.png'),
          onClick:()=>{
            this.onPageChange(-1)
          }
        }) }

        <div style={Style.roomPageIndexContainer}>
          <label style={Style.roomPageIndexLabel}>
          {
            this.state.currentRoomListIndex+1
          }
          {
            ' / '
          }
          {
            this.state.roomsCount?

            Math.ceil(this.state.roomsCount/10)
            : '1'
          }
          </label>
        </div>

        { this.renderPageButton({
          img:require('../img/arrow_R.png'),
          onClick:()=>{
            this.onPageChange(1)
          }
        })}
        </div>
      }

      </div>
    )
  }

  renderPageButton=({img,onClick})=>{
    return(
      <div style={Style.pageBtn} onClick={()=>{
        console.log('!!!!')
        if(onClick){
          onClick()
        }
      }}>
        <img style={Style.pageBtnImg}
        src={img}/>
      </div>
    )
  }



  render(){
    return(
      <MainViewContainer
      page_id={PAGES.PAGE_ROOMLIST}
      >
      {
        this.renderRoomList()
      }
      </MainViewContainer>
    )
  }



}

const Style={

  roomViewContainer:{
    flex:1,
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-end',
    // borderBottom: '2px solid #0f0',
    overflow:'auto',
  },
  roomScrollNav:{
    // backgroundColor:'#ff0000',
    // maxHeight:'400px',
    display:'flex',
    flex:1,
    borderBottom: '1px solid #dddddd',
    overflow:'auto',
  },
  roomBottomContainer:{
    minHeight:50 ,
    // border:'1px solid #000000',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
  },
  roomPageIndexContainer:{
    minWidth:60,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    // border:'1px solid #000000',
  },
  roomPageIndexLabel:{
    fontSize:16,
    fontWeight:'bold',
  },
  roomScrollList:{

    // columns: 2,
    height:'auto',
    flex:1,
    margin:0,
    padding:0,
    // textIndent:'10px',
    // backgroundColor:'#0000ff',
  },
  roomContainer:{
    height:100,
    width:'100%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    // paddingLeft:40,
    // paddingRight:40,
    // borderBottomWidth:2,
    // borderColor:'#dddddd',
    'borderBottom': '1px solid #dddddd',
  },
  roomNameContainer:{
    height:60,
    marginLeft:20,
    marginRight:20,
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    // border: '1px solid #000000',
    width:'100%',
  },
  roomNameLabel:{
    fontSize:18,
  },
  roomImg:{
    marginLeft:40,
    maxWidth:60,
    maxHeight:60,
    selectDisable:'user-select:none',
  },
  roomEnterBtn:{
    backgroundColor:'#dddddd',
    minWidth:100,
    height:40,
    display:'flex',
    // flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    // marginRight:40,
    borderRadius:6,
    padding:0,
  },
  roomEnterLabel:{
    fontSize:18,
    fontWeight:'bold',
    margin:0,
  },

  pageBtn:{
    // backgroundColor:'#dddddd',
    minWidth:40,
    height:40,
    display:'flex',

    justifyContent:'center',
    alignItems:'center',
    borderRadius:6,
    padding:0,
  },
  pageBtnImg:{
    height:20,
  }

}

export default (withRouter(RoomListView))
// export default  connect(mapStateToProps) (LoginView)
