import React, { useState, useEffect, Fragment, Component } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";

// import ListenerManager from './common/ListenerManager'

import {connect} from "@voxeet/react-redux-5.1.1";

import AppState from '../common/AppState'
import ConnectionManager from '../common/ConnectionManager'

import axios from 'axios';

import Loader from 'react-loader-spinner'

const mapStateToProps = (state) => ({
      conferenceStore: state.voxeet.conference,
      errorStore: state.voxeet.error,
      participantsStore: state.voxeet.participants,
})

class LoginView extends Component{
  constructor(props){
    super(props);
    this.state = {

    }
  }


  componentDidMount(){
    // ConnectionManager.instance.test_post_Api();
    // ConnectionManager.instance.test_get_Api();

    // ConnectionManager.instance.api_login();
    // ConnectionManager.instance.api_auth();
    if(ConnectionManager.instance.getIsLoginFromSession()){
      let path = `/RoomListView`;
      this.props.history.push(path);
    }


  }
  componentDidUpdate(){

  }

  isDoingLogin = false

  onClickLogin=async()=>{
    if(this.onClickLogin == true){ return}

    // let savedToken = sessionStorage.getItem('access_token')
    // console.log('savedToken:',savedToken);
    // let savedUserInfo = sessionStorage.getItem('user_info')
    // console.log('savedUserInfo:',savedUserInfo);

    // let history = useHistory();
    // let location = useLocation();
    // let { from } = location.state || { from: { pathname: "/" } };
    // console.log('!!!!!',this.usernameInput.value)
    // check input
    let login_username = this.usernameInput.value;
    let login_password = this.passwordInput.value;
    if(login_username == ''){
      return ;
    }
    if(login_password == ''){
      return ;
    }
    if(!login_username.match(/^[a-zA-Z0-9]+$/)){
       // formIsValid = false;
       // errors["name"] = "Only letters";
       // console.log('not ok')
    }
    // fakeAuth.authenticate(() => {
    //   history.replace(from);
    // });

    let res = await ConnectionManager.instance.api_login(login_username,login_password);
    console.log(res)
    // save login token
    // sessionStorage.setItem('access_token',res.data.token)
    // sessionStorage.setItem('user_info',JSON.stringify(res.data.user_info))
    if(res.isSuccess == true){
      ConnectionManager.instance.setLoginedData(res.data.token,res.data.user_info)
      ConnectionManager.instance.setOauthTokenObj(res.data.oauthTokenObj)
      let path = `/RoomListView`;
      this.props.history.push(path);
    }

    return;
    AppState.Auth(()=>{
      console.log('!!!')
      let path = `/RoomListView`;
      this.props.history.push(path);
    })


  }



  render (){return (
    <div style={Style.basicContainer}>
    <div style={Style.logoContainer}>
      <img style={Style.logoImg} src={require("../img/logo.png")}/>
    </div>


    {
      <div style={Style.inputContainer}>
        <input style={Style.idInput}
        ref={e=>this.usernameInput=e}
        type="text" id="username" name="username"
        placeholder='Username'
        />

        <input style={Style.idInput}
        ref={e=>this.passwordInput=e}
        type="password" id="password" name="password"
        placeholder='Password'
        />

        <button style={Style.LoginButton}
        onClick={this.onClickLogin}
        type="button">
        Login
        </button>
      </div>

    }
    {
      // <div style={Style.loaderContainer}>
        // <Loader
        //  type="TailSpin"
        //  color="#00BFFF"
        //  height={60}
        //  width={60}
        //  // timeout={3000} //3 secs
        //  />
       // </div>
    }

    </div>
  )}

}
const Style={
  basicContainer:{
    // backgroundColor:'#ff0000',
    // display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    height:'100vh',
  },
  logoContainer:{
    backgroundColor:'#eeeeee',
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-end',
    alignItems:'center',
    padding:20,

    height:160,
  },
  logoImg:{

  },
  inputContainer:{
    display:'flex',
    // height:'100%',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    paddingTop:20,
  },
  idInput:{
    marginTop:30,
    width:220,
    borderWidth:1,
    borderColor:'#dddddd',
    height:36,
    // textAlign: 'center',
    borderRadius:6,
    borderStyle:'solid',
    fontSize:16,
    paddingLeft:10,
    paddingRight:10,
  },

  LoginButton:{
    marginTop:60,
    width:240,
    height:36,
    justifyContent:'center',
    alignItems:'center',
    textAlign: 'center',
    fontFamily:'helvatica',
    borderWidth:0,
    backgroundColor:'#5CC4D4',
    color:'#ffffff',
    fontSize:18,
    fontWeight:'900',
  },
  loaderContainer:{
    // position:'absolute',
    display:'flex',
    height:'100%',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    paddingTop:60,
  },

}

export default (LoginView)
// export default  connect(mapStateToProps) (LoginView)
