import { reducer as voxeetReducer } from "@voxeet/react-components"
// import { reducers as voxeetReducer }
// // from "@voxeet/react-components"
// // from "./custom_node_modules/voxeet-uxkit-react-master/src/app/"
// from "./custom_node_modules/voxeet-uxkit-react-master/src/app/reducers/"

import React, { useState, useEffect, Fragment, Component } from 'react'

import thunkMidleware from "redux-thunk"
import { combineReducers, createStore, applyMiddleware } from "redux"


import { Provider as VoxeetProvider ,connect} from "@voxeet/react-redux-5.1.1";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
  useLocation
} from 'react-router-dom';

import LoginView from './login/LoginView'
import RoomListView from './room/RoomListView'
import SettingView from './room/SettingView'
import ComferenceView from './ComferenceView'

import AppState from './common/AppState'
import ConnectionManager from './common/ConnectionManager'

const reducers = combineReducers({
  voxeet: voxeetReducer,
})

const configureStore = () =>
  createStore(reducers, applyMiddleware(thunkMidleware))


// const [response, setResponse] = useState("");
// const [ws,setWs] = useState("");

// const LoginView =()=>{
//   return(
//     <div>
//       <h2> login </h2>
//     </div>
//   )
// }
//
// const RoomListView =()=>{
//   return(
//     <div>
//       <h2> RoomListView </h2>
//     </div>
//   )
// }

const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

const PrivateRoute=({ children, ...rest })=>{
  return (
    <Route
      {...rest}
      render={({ location }) =>
        // AppState.isLogined() ?
        ConnectionManager.instance.getIsLoginFromSession()?
        (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

class App extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }


  render (){return (
    <Router>
    <div style={Style.basicContainer}>

    {
      // <nav>
      //   <ul>
      //     <li>
      //       <Link to="/">login</Link>
      //     </li>
      //     <li>
      //       <Link to="/RoomListView">RoomListView</Link>
      //     </li>
      //     <li>
      //       <Link to="/ComferenceView">ComferenceView</Link>
      //     </li>
      //   </ul>
      // </nav>
    }

    {
    <Switch>
     {
       <Route exact path="/" component={LoginView}/>
       // <Route path="/">
       //  <LoginView/>
       // </Route>
     }
     {
       // <Route path="/RoomListView" component={RoomListView}/>
       // <PrivateRoute path="/RoomListView" component={RoomListView}/>
       <PrivateRoute path="/RoomListView">
       <RoomListView/>
       </PrivateRoute>
     }

     {
       <PrivateRoute path="/SettingView">
       <SettingView/>
       </PrivateRoute>
     }

     {
       // <Route path="/ComferenceView">
       <PrivateRoute path="/ComferenceView">
       <VoxeetProvider store={configureStore()}>

         {
           <ComferenceView/>
         }

       </VoxeetProvider>
       </PrivateRoute>
       // </Route>
     }
     </Switch>
   }
    {
      // false&&
      // <VoxeetProvider store={configureStore()}>
      //
      //   {
      //     <ComferenceView/>
      //   }
      //
      // </VoxeetProvider>
    }


    </div>
    </Router>
  )}

}

const Style={
  basicContainer:{
    backgroundColor:'#',
    height:'100%',
    // margin:0,
    // padding:0,
  }
}



export default  (App)
