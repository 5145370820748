import React  from 'react';


export default class ListenerManager {


  static _Listeners = {
    count: 0,
    refs: { },
    list:[],
  }

  static EventList={
    roleUpdate:'roleUpdate',// when client change
    ApplicationStatusUpdate:'ApplicationStatusUpdate',
  }

  static randomString(){
    var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = 20; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  static addEventListener(_eventName, _callback) {
    // if (
    //   (eventName) === 'string' &&
    //   type(callback) === 'function'
    // ) {

      // ListenerManager._Listeners.count++
      var eventId = 'L'+ListenerManager.randomString() //'l' + ListenerManager._Listeners.count
      // console.log('eventId',eventId , _eventName ,_callback )
      // console.log('addEventListener now:',ListenerManager._Listeners)
      var x = {
        name: _eventName,
        callback:_callback,
      }
      ListenerManager._Listeners.refs[eventId] = x

      // ListenerManager._Listeners.list.push({id:eventId ,name: _eventName , callback:_callback })

      // console.log('addEventListener now:',ListenerManager._Listeners.refs[eventId])
      return eventId
    // }
    // return false
  }

  static removeEventListener(id) {
    // console.log('removeEventListener:',id,ListenerManager._Listeners.refs[id])
    // if (type(id) === 'string') {
      return delete ListenerManager._Listeners.refs[id]
    // }
    return false
    // for (var i = 0; i < ListenerManager._Listeners.list.length; i++) {
    //   if( ListenerManager._Listeners.list[i].id = id){
    //       ListenerManager._Listeners.list.splice(i,1);
    //   }
    // }
  }

  static removeAllListeners() {
    // let removeError = false
    // Object.keys(ListenerManager._Listeners.refs).forEach(_id => {
    //   const removed = delete ListenerManager._Listeners.refs[_id]
    //   removeError = (!removeError) ? !removed : removeError
    // })
    // return !removeError

  }

  static emitEvent(eventName, data) {    
    console.log('emitEvent:',eventName,data ,'|',ListenerManager._Listeners)
    Object.keys(ListenerManager._Listeners.refs).forEach(_id => {
      // console.log(ListenerManager._Listeners.refs[_id] ,'|',ListenerManager._Listeners.refs[_id].name)
      if (
        ListenerManager._Listeners.refs[_id] &&
        eventName === ListenerManager._Listeners.refs[_id].name
      ){
        // console.log('Emit :',ListenerManager._Listeners.refs[_id])
        ListenerManager._Listeners.refs[_id].callback(data)
      }

    })

    // for (var i = 0; i < ListenerManager._Listeners.list.length; i++) {
    //   if( ListenerManager._Listeners.list[i].name == eventName){
    //   //  console.log('emitEvent list:',ListenerManager._Listeners.list[i])
    //     if(ListenerManager._Listeners.list[i].callback){
    //
    //       ListenerManager._Listeners.list[i].callback(data)
    //     }
    //   }
    // }
  }

}
