import React  from 'react';


export default class AppState {
  static currentUser = null

  static Auth(callback){
    this.currentUser = {
      username:'test name',

    }
    if(callback){
      callback()
    }
  }
  static isLogined(){
    // console.log('isLogined:',this.currentUser)
    if(this.currentUser !=null){
      return true
    }else {
      return false
    }
  }

}
