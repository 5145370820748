// import { reducer as voxeetReducer } from "@voxeet/react-components"
import { reducers as voxeetReducer }
// from "@voxeet/react-components"
// from "./custom_node_modules/voxeet-uxkit-react-master/src/app/"
from "./custom_node_modules/voxeet-uxkit-react-master/src/app/reducers"


import React, { useState, useEffect, Fragment, Component } from 'react'

import thunkMidleware from "redux-thunk"
import { combineReducers, createStore, applyMiddleware } from "redux"
import { withRouter } from "react-router-dom";
// import {
//   ConferenceRoom,
//   VoxeetProvider,
//   // ActionsButtons,
// }
// from "@voxeet/react-components"
// from "./custom_node_modules/voxeet-uxkit-react-master/src/app/"
// from "./custom_node_modules/voxeet-uxkit-react-master/src/app/VoxeetReactComponents"
import { Provider as VoxeetProvider ,connect} from "@voxeet/react-redux-5.1.1";
import  ConferenceRoom from"./custom_node_modules/voxeet-uxkit-react-master/src/app/components/ConferenceRoom"
// import {
//   ConferenceRoom
// }
// from "./custom_node_modules/voxeet-uxkit-react-master/src/app/components/ConferenceRoom"

import CustomActionsButtons from './ActionsButtons'
// import CustomActionsButtons from './CustomActionsButtons'
// Import Style
import "@voxeet/react-components/dist/voxeet-react-components.css"
// import "./custom_node_modules/voxeet-uxkit-react-master/src/styles/components/index.less"
// import { Buttons } from "@voxeet/react-components";
import webSocket from 'socket.io-client'

import ListenerManager from './common/ListenerManager'

import { Actions as ChatActions } from "./custom_node_modules/voxeet-uxkit-react-master/src/app/actions/ChatActions";
// const fs = require('fs');
// import * as fs from 'fs'

import axios from 'axios';

import ConnectionManager from './common/ConnectionManager'

// const reducers = combineReducers({
//   voxeet: voxeetReducer,
// })
//
// const configureStore = () =>
//   createStore(reducers, applyMiddleware(thunkMidleware))
const mapStateToProps = (state) => ({
      conferenceStore: state.voxeet.conference,
      errorStore: state.voxeet.error,
      participantsStore: state.voxeet.participants,

      inputManager: state.voxeet.inputManager,
      controls: state.voxeet.controls,
      participants:state.voxeet.participants,
      chat: state.voxeet.chat,

})

// const settings = {
//   // consumerKey: "wU2M8zVqYg5Phqu6TK8Iew==",
//   // consumerSecret: "bqyMH-kf4ciFbUN_0NfcCwcFhwn6Sm19kci2utkZecg=",
//   consumerKey: "ThsnuLUXim92Eu9cbDB1xA==",
//   consumerSecret: "ntOHLOK6Iqk7d9aV96r8LiQfszmdDv3V6fgqSYu3S3I=",
//   // conferenceAlias: "Webrct-test-react",
//   conferenceAlias: "test-webrct",
//   // conferenceAlias: "123",
// }

// const [response, setResponse] = useState("");
// const [ws,setWs] = useState("");

// const ENDPOINT = "http://localhost:65001";
// const ENDPOINT = "https://dolby-webrct.epic-comm.net:65003";
const ENDPOINT = ConnectionManager.ENDPOINT;
// const ENDPOINT = "wss://dolby-webrct.epic-comm.net:65001";
// const ENDPOINT = "http://123.176.102.151:65001";
class ComferenceView extends Component{
  constructor(props){
    super(props);
    this.state = {
      ws:null,
      response:null,
      key_sendMessageListener:null,
      key_sendFileListener:null,
      settings :{
        // consumerKey: "wU2M8zVqYg5Phqu6TK8Iew==",
        // consumerSecret: "bqyMH-kf4ciFbUN_0NfcCwcFhwn6Sm19kci2utkZecg=",
        consumerKey: "ThsnuLUXim92Eu9cbDB1xA==",
        consumerSecret: "ntOHLOK6Iqk7d9aV96r8LiQfszmdDv3V6fgqSYu3S3I=",
        // conferenceAlias: "Webrct-test-react",
        conferenceAlias: "test-webrct",
        // conferenceAlias: "123",
      },
      oauthObj:null,
      userInfo:{
        name:'',
        externalId: '',
        avatarUrl: null,
      }

    }
    let tempUserInfo = ConnectionManager.instance.getUserInfoAlsoSavedUserInfo();
    console.log('tempUserInfo:',tempUserInfo,tempUserInfo.name)
    if(tempUserInfo){
      this.state.userInfo.name = tempUserInfo.name
      this.state.userInfo.avatarUrl = tempUserInfo.avatarUrl
    }
    console.log(this.state.userInfo)
    console.log('roomData1:',this.props.history.location.state)
    if(this.props.history
    && this.props.history.location
    && this.props.history.location.state
    && this.props.history.location.state.roomData){
      console.log('roomData2:',this.props.history.location.state.roomData)
      this.state.settings.conferenceAlias = this.props.history.location.state.roomData.alias
    }

    let oauthObj = ConnectionManager.instance.getOauthObjAlsoSavedOauthObj();
    if(oauthObj){
      this.state.oauthObj = oauthObj
    }
  }

  componentDidMount(){
    let key1 = ListenerManager.addEventListener('sendMessage',this.sendMessageFromListener)
    let key2 = ListenerManager.addEventListener('sendFile',this.sendFileFromListener)
    this.setState({
      key_sendMessageListener:key1,
      key_sendFileListener:key2,
    })

    ConnectionManager.instance.connectWebSocket()
    console.log('componentDidMount:',this.props)
    // this.loopLog()
  }
  componentWillUnmount(){
    this.isLoop = false

    ListenerManager.removeEventListener(this.state.key_sendMessageListener)
    ListenerManager.removeEventListener(this.state.key_sendFileListener)
  }

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  isLoop = false
  loopLog=async()=>{
    this.isLoop = true;
    while (this.isLoop) {
      console.log('componentDidMount:',this.props);
      await this.sleep(1000);
    }
  }
  componentDidUpdate(){

  }

  connectWebSocket = () => {
      ConnectionManager.instance.connectWebSocket()
  }

  sendMessageFromListener=(data)=>{
    console.log('obj',data)
    // this.sendMessage("getMessageLess",data)
    this.sendMessage("getMessageAll",data)
  }

  sendFileFromListener=(data)=>{
    console.log('sendFileFromListener:',data)
    ConnectionManager.instance.api_uploadFile(data,this.state.settings.conferenceAlias)
  }



  autoEnterRoom=(event)=>{ // should call after stream connect
    console.log('autoEnterRoom:',this.props ,'|', this.state.settings.conferenceAlias)

    let roomData={
      name:'',
      room:'',
    }
    if(this.props.participants){
      roomData.name = this.props.participants.currentUser.name;
      roomData.room = this.state.settings.conferenceAlias;
    }
    ConnectionManager.instance.socket_enterRoom(roomData)
  }

  sendMessage = (name,data) => {
    console.log('sendMessage:',this.state.settings.conferenceAlias)
     ConnectionManager.instance.socket_sendMessage(
       name,data,
       this.state.settings.conferenceAlias
     );
  }
  disConnectWebSocket = () =>{
     ConnectionManager.instance.closeWebSocket()
  }
  // */

  onConnectRoom=()=>{
    if(ConnectionManager.instance.currentSocket){
      this.autoEnterRoom();
      ConnectionManager.instance.initWebSocketListener({
        callback_FromAPI:(data)=>{
          // console.log('callback_FromAPI')
          this.setState({
            response:data
          })
        },
        callback_getMessageAll:(dataParsed)=>{
          // console.log('callback_getMessageAll')
            this.props.dispatch(ChatActions.addMessage(dataParsed));
        },
        callback_getMessageLess:(dataParsed)=>{
          // console.log('callback_getMessageLess')
            this.props.dispatch(ChatActions.addMessage(dataParsed));
        },

      })
    }
  }

  onLeaveRoom=()=>{
    ConnectionManager.instance.api_leaveRoom((obj)=>{
      if(obj.isSuccess == true){
        this.disConnectWebSocket()
        let path = `/RoomListView`;
        // this.props.history.push(path);
        this.props.history.push('/');
        this.props.history.replace('/RoomListView');
      }
    })

  }

  render (){return (
    <div>
    {
      // <p>
      //   It's <time dateTime={this.state.response}>{this.state.response}</time>
      // </p>
    }
    {
      // <select onChange={this.changeRoom}>
      //     <option value=''>請選擇房間</option>
      //     <option value='room1'>房間一</option>
      //     <option value='room2'>房間二</option>
      // </select>
    }
    {
      // <input type='button' value='斷線' onClick={this.disConnectWebSocket} />
    }
    {
      // <div>
      //     <input type='button' value='連線' onClick={this.connectWebSocket} />
      //     <input type='button' value='送出訊息，只有自己收到回傳' onClick={() => { this.sendMessage('getMessage') }} />
      //     <input type='button' value='送出訊息，讓所有人收到回傳' onClick={() => { this.sendMessage('getMessageAll') }} />
      //     <input type='button' value='送出訊息，除了自己外所有人收到回傳' onClick={() => { this.sendMessage('getMessageLess') }} />
      // </div>
    }

    {
      // false&&
      // <VoxeetProvider store={configureStore()}>


          <ConferenceRoom
          autoJoin
          // consumerKey={this.state.settings.consumerKey}
          // consumerSecret={this.state.settings.consumerSecret}
          oauthToken={this.state.oauthObj.access_token}
          conferenceAlias={this.state.settings.conferenceAlias}
          handleOnConnect={this.onConnectRoom}
          handleOnLeave={this.onLeaveRoom}
          userInfo={this.state.userInfo}
          // actionsButtons={ActionsButtons}
          // actionsButtons={CustomActionsButtons}


          // actionsButtons={(obj)=>{
          //   console.log('actionsButtons:',obj)
          //   return(
          //     <div>
          //
          //     </div>
          //   )
          // }}


          />

      // </VoxeetProvider>
    }


    </div>
  )}



}



export default  connect(mapStateToProps) (withRouter(ComferenceView))
